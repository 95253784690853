import * as React from "react";

const PricingTable = () => {

    const submitButton = (buySubscriptionType) => {
        const go = () => {
            console.log(buySubscriptionType);
        }

        return <div className="card-footer ">
            <a href="https://platform.bitspreader.com/register" target="_self" className="btn btn-fullcolor">
                {(buySubscriptionType === 'STARTER') ?
                    <>TRY FREE FOR 3 DAYS</> : <>SIGN UP</>}
            </a>
        </div>


    }

    return (
        <section className="hg_section pt-30 bg-white">
            <div className="container">
                <div className="row">

                    <div className="card-deck text-center p-5">
                        <div className="card mb-2 box-shadow">
                            <div className="card-header">
                                <h4 className="my-0 card-title plan-title">Starter</h4>
                            </div>
                            <div className="card-body ">
                                <h1 className="card-title plan-price">$295<small className="text-muted">/
                                    month</small>
                                </h1>
                                <div className={"text-left"}>
                                    <ul className="list-style1 mt-3 mb-4">
                                        <li>Spread charts</li>
                                        <li>Real time & historical spread market data</li>
                                        <li>Real time implied spread order book</li>
                                        <li>Spread Trading Terminal</li>
                                        <li>Position and exposure tracking</li>
                                        <li>Spread Matrix</li>
                                        <li>Unlimited market spread orders</li>
                                        <li>24 active AutoTrigger orders, 360 per day</li>
                                        <li><b>4</b> active AutoSpreading orders, 96 per day</li>
                                        <li>Tech Support</li>
                                    </ul>
                                </div>
                            </div>
                            {submitButton("STARTER")}

                        </div>
                        <div className="card mb-2 box-shadow">
                            <div className="card-header">
                                <h4 className="my-0 card-title plan-title">Standard</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title plan-price">$595<small className="text-muted">/
                                    month</small>
                                </h1>
                                <div className={"text-left"}>
                                    <ul className="list-style1 mt-3 mb-4">
                                        <li>Spread charts</li>
                                        <li>Real time & historical spread market data</li>
                                        <li>Real time implied spread order book</li>
                                        <li>Spread Trading Terminal</li>
                                        <li>Position and exposure tracking</li>
                                        <li>Spread Matrix</li>
                                        <li>Unlimited market spread orders</li>
                                        <li>24 active AutoTrigger orders, 360 per day</li>
                                        <li><b>12</b> active AutoSpreading orders, 300 per day</li>
                                        <li>Tech Support</li>
                                    </ul>
                                </div>
                            </div>
                            {submitButton("STANDARD")}
                        </div>
                        <div className="card mb-2 box-shadow">
                            <div className="card-header">
                                <h4 className="my-0 card-title plan-title">Professional</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title plan-price">Custom</h1>
                                <div className={"text-left"}>
                                    <ul className="list-style1 mt-3 mb-4">
                                        <li>Spread charts</li>
                                        <li>Real time & historical spread market data</li>
                                        <li>Real time implied spread order book</li>
                                        <li>Spread Trading Terminal</li>
                                        <li>Position and exposure tracking</li>
                                        <li>Spread Matrix</li>
                                        <li>Unlimited market spread orders</li>
                                        <li>Unlimited active AutoTrigger orders</li>
                                        <li><b>Unlimited</b> active AutoSpreading orders</li>
                                        <li><b>Spread Trading bots</b></li>
                                        <li><b>Custom infrastructure</b></li>
                                        <li><b>Custom development services</b></li>
                                        <li>Tech Support</li>
                                    </ul>
                                </div>
                            </div>
                            {submitButton("PROFESSIONAL")}
                        </div>


                    </div>
                </div>


            </div>
        </section>


    );
}

export default PricingTable;
